<template>
  <div>
    <b-form class="form" @submit.stop.prevent="save_form">
      <!--begin::Subheader-->
      <div id="kt_subheader" class="subheader py-2 py-lg-4 subheader-solid">
        <div
          class="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap"
        >
          <!--begin::Details-->
          <div class="d-flex align-items-center flex-wrap mr-2">
            <!--begin::Title-->
            <h5 class="text-dark font-weight-bold mt-2 mb-2 mr-5">
              Edit Partner CRM Data
            </h5>
            <!--end::Title-->
            <!--begin::Separator-->
            <div
              class="subheader-separator subheader-separator-ver mt-2 mb-2 mr-5 bg-gray-200"
            ></div>
            <!--end::Separator-->

            <!--begin::Group Actions-->
            <div
              id="kt_subheader_group_actions"
              class="d-flex- align-items-center flex-wrap mr-2 d-none"
            >
              <div class="text-dark-50 font-weight-bold">
                <span id="kt_subheader_group_selected_rows">23</span>Selected:
              </div>
              <div class="d-flex ml-6">
                <div
                  id="kt_subheader_group_actions_status_change"
                  class="dropdown mr-2"
                >
                  <button
                    type="button"
                    class="btn btn-light-primary font-weight-bolder btn-sm dropdown-toggle"
                    data-toggle="dropdown"
                  >
                    Update Status
                  </button>
                  <div class="dropdown-menu p-0 m-0 dropdown-menu-sm"></div>
                </div>
                <button
                  id="kt_subheader_group_actions_fetch"
                  class="btn btn-light-primary font-weight-bolder btn-sm mr-2"
                  data-toggle="modal"
                  data-target="#kt_datatable_records_fetch_modal"
                >
                  Fetch Selected
                </button>
                <button
                  id="kt_subheader_group_actions_delete_all"
                  class="btn btn-light-primary font-weight-bolder btn-sm mr-2"
                >
                  Delete All
                </button>
              </div>
            </div>
            <!--end::Group Actions-->
          </div>
          <!--end::Details-->
          <!--begin::Toolbar-->
          <div class="d-flex align-items-center">
            <a
              class="btn btn-light-primary font-weight-bolder mr-2"
              @click="$router.go(-1)"
            >
              <i class="ki ki-long-arrow-back icon-xs"></i>Back</a
            >
            <div class="btn-group">
              <button type="submit" class="btn btn-primary font-weight-bolder">
                <i class="ki ki-check icon-xs"></i>Save Form
              </button>
            </div>
          </div>
          <!--end::Toolbar-->
        </div>
      </div>
      <!--end::Subheader-->

      <div class="row">
        <div class="col-lg-6">
          <div class="card card-custom ">
            <div class="card-header border-0">
              <div class="card-title">
                <span class="card-icon">
                  <i class="flaticon2-chat-1 text-dark"></i>
                </span>
                <h3 class="card-label text-dark">Customize</h3>
              </div>
            </div>
            <div
              class="separator separator-solid separator-dark opacity-50"
            ></div>
            <div class="card-body text-dark">
             Great content is hatching
            </div>
          </div>
        </div>

        <div class="col-lg-6">
          <div class="card card-custom bg-primary">
            <div class="card-header border-0">
              <div class="card-title">
                <span class="card-icon">
                  <i class="flaticon2-chat-1 text-white"></i>
                </span>
                <h3 class="card-label text-white">Company Goal Settings</h3>
              </div>
            </div>
            <div
              class="separator separator-solid separator-white opacity-20"
            ></div>
            <div class="card-body text-white">
              Great content is also coming here
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-12">
          <div class="card card-custom card-sticky">
            <div class="card-body">
              <div class="row">
                <div class="col-lg-6">
                  <div class="col-xl-2"></div>
                  <div class="col-xl-8">
                    <div class="my-5">
                      <h3 class="text-dark font-weight-bold mb-10">
                        CRM Info:
                      </h3>
                      <div class="form-group row">
                        <label class="col-3">Partner Status</label>
                        <div class="col-9">
                          <b-form-select
                            id="input-horizontal"
                            v-model="partner_status"
                            :options="partner_status_options"
                          ></b-form-select>
                        </div>
                      </div>

                      <div class="form-group row">
                        <label class="col-4">Sales Channel</label>
                        <div class="col-8">
                          <b-form-select id="input-horizontal"></b-form-select>
                        </div>
                      </div>

                      <div class="form-group row">
                        <label class="col-4">Assigned Pricing List</label>
                        <div class="col-8">
                          <v-autocomplete
                            v-model="sellerPricelist"
                            filled
                            solo
                            color="blue-grey lighten-2"
                            label="Select Companies to Assign"
                            :items="pricelist"
                            item-text="listname"
                            item-value="id"
                          >
                          </v-autocomplete>
                        </div>
                      </div>
                      <div class="form-group row">
                        <label class="col-3">CRM Quick Note</label>
                        <div class="col-9">
                          <b-form-textarea
                            id="textarea"
                            v-model="crm_quick_note"
                            placeholder="Enter Note"
                            rows="5"
                            max-rows="18"
                          ></b-form-textarea>
                        </div>
                      </div>

                      <div class="form-group row">
                        <label class="col-4">Sales Rep</label>
                        <div class="col-8">
                          <input
                            v-model="sales_rep"
                            class="form-control"
                            type="text"
                          />
                          <span class="form-text text-muted"
                            >Assigned sales rep</span
                          >
                        </div>
                      </div>
                      <div class="form-group row">
                        <label class="col-4">Custom Order Notes</label>
                        <div class="col-8">
                          <b-form-textarea
                            id="textarea"
                            v-model="custom_order_note"
                            placeholder="Enter Note"
                            rows="5"
                            max-rows="18"
                          ></b-form-textarea>
                        </div>
                      </div>

                      <div class="form-group row">
                        <label class="col-4">Custom Shipping Notes</label>
                        <div class="col-8">
                          <b-form-textarea
                            id="textarea"
                            v-model="custom_shipping_note"
                            placeholder="Enter Note"
                            rows="5"
                            max-rows="18"
                          ></b-form-textarea>
                        </div>
                      </div>

         
                    </div>
                  </div>
                </div>

                <div class="col-lg-6">
                  <div class="col-xl-2"></div>

                  <div class="col-xl-8">
                    <div class="my-5">
                      <h3 class="text-dark font-weight-bold mb-5">
                        Sales Goals
                      </h3>

                      <div class="form-group row">
                        <label class="col-5">Yearly Sales Goal</label>
                        <div class="col-7">
                          <b-form-input
                            v-model="$v.yearly_sales_goal.$model"
                            class="form-control"
                            :state="validateState('yearly_sales_goal')"
                            aria-describedby="input-2-live-feedback"
                            placeholder="ex; 10000.00"
                          ></b-form-input>

                          <b-form-invalid-feedback id="input-2-live-feedback">
                            Yearly Sales Goal is required and a valid numeric.
                          </b-form-invalid-feedback>
                        </div>
                      </div>
                      <div class="form-group row">
                        <div class="col-2"></div>
                        <label class="col-3">Jan. Goal</label>
                        <div class="col-7">
                          <b-form-input
                            v-model="$v.jan_goal.$model"
                            class="form-control"
                            :state="validateState('jan_goal')"
                            aria-describedby="input-3-live-feedback"
                            placeholder="1000.00"
                          ></b-form-input>

                          <b-form-invalid-feedback id="input-3-live-feedback">
                            Jan. Goal is required and a valid numeric.
                          </b-form-invalid-feedback>
                        </div>
                      </div>

                      <div class="form-group row">
                        <div class="col-2"></div>
                        <label class="col-3">Feb. Goal</label>
                        <div class="col-7">
                          <b-form-input
                            v-model="$v.feb_goal.$model"
                            class="form-control"
                            :state="validateState('feb_goal')"
                            aria-describedby="input-4-live-feedback"
                            placeholder="1000.00"
                          ></b-form-input>

                          <b-form-invalid-feedback id="input-4-live-feedback">
                            Feb. Goal is required and a valid numeric.
                          </b-form-invalid-feedback>
                        </div>
                      </div>
                      <div class="form-group row">
                        <div class="col-2"></div>
                        <label class="col-3">Mar. Goal</label>
                        <div class="col-7">
                          <b-form-input
                            v-model="$v.mar_goal.$model"
                            class="form-control"
                            :state="validateState('mar_goal')"
                            aria-describedby="input-5-live-feedback"
                            placeholder="1000.00"
                          ></b-form-input>

                          <b-form-invalid-feedback id="input-5-live-feedback">
                            Mar. Goal is required and a valid numeric.
                          </b-form-invalid-feedback>
                        </div>
                      </div>
                      <div class="form-group row">
                        <div class="col-2"></div>
                        <label class="col-3">Apr. Goal</label>
                        <div class="col-7">
                          <b-form-input
                            v-model="$v.apr_goal.$model"
                            class="form-control"
                            :state="validateState('apr_goal')"
                            aria-describedby="input-6-live-feedback"
                            placeholder="1000.00"
                          ></b-form-input>

                          <b-form-invalid-feedback id="input-6-live-feedback">
                            Apr. Goal is required and a valid numeric.
                          </b-form-invalid-feedback>
                        </div>
                      </div>
                      <div class="form-group row">
                        <div class="col-2"></div>
                        <label class="col-3">May. Goal</label>
                        <div class="col-7">
                          <b-form-input
                            v-model="$v.may_goal.$model"
                            class="form-control"
                            :state="validateState('may_goal')"
                            aria-describedby="input-7-live-feedback"
                            placeholder="1000.00"
                          ></b-form-input>

                          <b-form-invalid-feedback id="input-7-live-feedback">
                            May. Goal is required and a valid numeric.
                          </b-form-invalid-feedback>
                        </div>
                      </div>
                      <div class="form-group row">
                        <div class="col-2"></div>
                        <label class="col-3">Jun. Goal</label>
                        <div class="col-7">
                          <b-form-input
                            v-model="$v.jun_goal.$model"
                            class="form-control"
                            :state="validateState('jun_goal')"
                            aria-describedby="input-8-live-feedback"
                            placeholder="1000.00"
                          ></b-form-input>

                          <b-form-invalid-feedback id="input-8-live-feedback">
                            Jun. Goal is required and a valid numeric.
                          </b-form-invalid-feedback>
                        </div>
                      </div>
                      <div class="form-group row">
                        <div class="col-2"></div>
                        <label class="col-3">Jul. Goal</label>
                        <div class="col-7">
                          <b-form-input
                            v-model="$v.jul_goal.$model"
                            class="form-control"
                            :state="validateState('jul_goal')"
                            aria-describedby="input-14-live-feedback"
                            placeholder="1000.00"
                          ></b-form-input>

                          <b-form-invalid-feedback id="input-14-live-feedback">
                            Jul. Goal is required and a valid numeric.
                          </b-form-invalid-feedback>
                        </div>
                      </div>
                      <div class="form-group row">
                        <div class="col-2"></div>
                        <label class="col-3">Aug. Goal</label>
                        <div class="col-7">
                          <b-form-input
                            v-model="$v.aug_goal.$model"
                            class="form-control"
                            :state="validateState('aug_goal')"
                            aria-describedby="input-9-live-feedback"
                            placeholder="1000.00"
                          ></b-form-input>

                          <b-form-invalid-feedback id="input-9-live-feedback">
                            Aug. Goal is required and a valid numeric.
                          </b-form-invalid-feedback>
                        </div>
                      </div>
                      <div class="form-group row">
                        <div class="col-2"></div>
                        <label class="col-3">Sept. Goal</label>
                        <div class="col-7">
                          <b-form-input
                            v-model="$v.sep_goal.$model"
                            class="form-control"
                            :state="validateState('sep_goal')"
                            aria-describedby="input-10-live-feedback"
                            placeholder="1000.00"
                          ></b-form-input>

                          <b-form-invalid-feedback id="input-10-live-feedback">
                            Sept. Goal is required and a valid numeric.
                          </b-form-invalid-feedback>
                        </div>
                      </div>
                      <div class="form-group row">
                        <div class="col-2"></div>
                        <label class="col-3">Oct. Goal</label>
                        <div class="col-7">
                          <b-form-input
                            v-model="$v.oct_goal.$model"
                            class="form-control"
                            :state="validateState('oct_goal')"
                            aria-describedby="input-11-live-feedback"
                            placeholder="1000.00"
                          ></b-form-input>

                          <b-form-invalid-feedback id="input-11-live-feedback">
                            Oct. Goal is required and a valid numeric.
                          </b-form-invalid-feedback>
                        </div>
                      </div>
                      <div class="form-group row">
                        <div class="col-2"></div>
                        <label class="col-3">Nov. Goal</label>
                        <div class="col-7">
                          <b-form-input
                            v-model="$v.nov_goal.$model"
                            class="form-control"
                            :state="validateState('nov_goal')"
                            aria-describedby="input-13-live-feedback"
                            placeholder="1000.00"
                          ></b-form-input>

                          <b-form-invalid-feedback id="input-13-live-feedback">
                            Nov. Goal is required and a valid numeric.
                          </b-form-invalid-feedback>
                        </div>
                      </div>
                      <div class="form-group row">
                        <div class="col-2"></div>
                        <label class="col-3">Dec. Goal</label>
                        <div class="col-7">
                          <b-form-input
                            v-model="$v.dec_goal.$model"
                            class="form-control"
                            :state="validateState('dec_goal')"
                            aria-describedby="input-12-live-feedback"
                            placeholder="1000.00"
                          ></b-form-input>

                          <b-form-invalid-feedback id="input-12-live-feedback">
                            Dec. Goal is required and a valid numeric.
                          </b-form-invalid-feedback>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-form>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import { mapActions } from "vuex";

import { validationMixin } from "vuelidate";
import { decimal, required } from "vuelidate/lib/validators";
import { GetCrms } from "@/graphql/queries/GetCrms.gql";

export default {
  mixins: [validationMixin],
  data() {
    return {
      supplierCompany: "",
      pricelist: [],
      sellerPricelist: "",
      partner_status: "Active",
      partner_status_options: [
        { value: "Active", text: "Active" },
        { value: "In-Active", text: "In-Active" },
        { value: "Probationary", text: "Probationary" },
        { value: "New", text: "New" },
        { value: "Premier", text: "Premier" },
        { value: "Trial", text: "Trial" },
      ],
      crm_quick_note: "",
      sales_rep: "",
      custom_order_note: "",
      custom_shipping_note: "",
      credit_limit: null,
      yearly_sales_goal: null,
      jan_goal: null,
      feb_goal: null,
      mar_goal: null,
      apr_goal: null,
      may_goal: null,
      jun_goal: null,
      jul_goal: null,
      aug_goal: null,
      sep_goal: null,
      oct_goal: null,
      nov_goal: null,
      dec_goal: null,
      seller_company_id: "",
    };
  },
  validations: {
    credit_limit: {
      required,
      decimal,
    },
    yearly_sales_goal: {
      required,
      decimal,
    },
    jan_goal: {
      decimal,
    },
    feb_goal: {
      decimal,
    },
    mar_goal: {
      decimal,
    },
    apr_goal: {
      decimal,
    },
    may_goal: {
      decimal,
    },
    jun_goal: {
      decimal,
    },
    jul_goal: {
      decimal,
    },
    aug_goal: {
      decimal,
    },
    sep_goal: {
      decimal,
    },
    oct_goal: {
      decimal,
    },
    nov_goal: {
      decimal,
    },
    dec_goal: {
      decimal,
    },
  },
  mounted() {
    setTimeout(() => {
      this.getCrmTotalCount({
        params:
          "supplier_company=" +
          this.$store.state.auth.user.company_relation +
          "&id=" +
          this.$route.params.id,
      }).then((res) => {
        if (res.data == 0) {
          this.$router.push("/crm/partners");
        }
      });

      this.getPricelist().then((pres) => {
        this.pricelist.push({
          id: null,
          listname: "None",
        });
        for (let i in pres.data) {
          this.pricelist.push({
            id: pres.data[i].id,
            listname: pres.data[i].listname,
          });
        }
        // console.log(this.pricelist)
      });

      this.getCrmById({
        id: this.$route.params.id,
      }).then((res) => {
        this.supplierCompany = res.data.supplier_company;
        this.seller_company_id = res.data.seller_company.id;
        this.partner_status = res.data.partner_status;
        this.crm_quick_note = res.data.crm_quick_note;
        this.sales_rep = res.data.sales_rep;
        this.custom_order_note = res.data.custom_order_note;
        this.custom_shipping_note = res.data.custom_shipping_note;
        this.credit_limit = res.data.credit_limit;
        this.yearly_sales_goal = res.data.yearly_sales_goal;
        this.jan_goal = res.data.jan_goal;
        this.feb_goal = res.data.feb_goal;
        this.mar_goal = res.data.mar_goal;
        this.apr_goal = res.data.apr_goal;
        this.may_goal = res.data.may_goal;
        this.jun_goal = res.data.jun_goal;
        this.jul_goal = res.data.jul_goal;
        this.aug_goal = res.data.aug_goal;
        this.sep_goal = res.data.sep_goal;
        this.oct_goal = res.data.oct_goal;
        this.nov_goal = res.data.nov_goal;
        this.dec_goal = res.data.dec_goal;
        if (res.data.pricelist != null) {
          if (res.data.pricelist.id != undefined) {
            this.sellerPricelist = res.data.pricelist.id;
          } else {
            this.sellerPricelist = 0;
          }
        } else {
          this.sellerPricelist = 0;
          // this.getCompanyInfo({
          //   id: this.$store.state.auth.user.company_relation
          // }).then((cres) => {
          //   if(cres.data.default_pricelist != null) {
          //     this.sellerPricelist = cres.data.default_pricelist.id;
          //   }
          // });
        }
      });
    }, 1000);
  },
  apollo: {
    // todo use the value in this instead of the the call in mounted.
    crms: {
      query: GetCrms,
      variables() {
        return {
          supplier_company: this.$store.state.auth.user.company_relation,
          seller_company: this.$route.params.id,
        };
      },
      skip() {
        return (
          !this.$store.state.auth.user ||
          this.$store.state.auth.user.company_relation === null ||
          this.$store.state.auth.user.company_relation === undefined
        );
      },
    },
  },
  methods: {
    ...mapActions([
      "updateCrm",
      "getCrmById",
      "getCrmTotalCount",
      "getPricelist",
      "getCompanyInfo",
    ]),
    validateState(name) {
      const { $dirty, $error } = this.$v[name];
      return $dirty ? !$error : null;
    },
    save_form(e) {
      e.preventDefault();
      this.$v.$touch();
      if (this.$v.$anyError) {
        return;
      }
      if (this.supplierCompany.default_pricelist) {
        if (this.sellerPricelist == 0 || this.sellerPricelist == null) {
          this.sellerPricelist = this.supplierCompany.default_pricelist;
        }
      } else {
        this.sellerPricelist = undefined;
      }
      this.updateCrm({
        id: this.$route.params.id,
        partner_status: this.partner_status,
        crm_quick_note: this.crm_quick_note,
        sales_rep: this.sales_rep,
        custom_order_note: this.custom_order_note,
        custom_shipping_note: this.custom_shipping_note,
        credit_limit: this.credit_limit,
        yearly_sales_goal: this.yearly_sales_goal,
        jan_goal: this.jan_goal,
        feb_goal: this.feb_goal,
        mar_goal: this.mar_goal,
        apr_goal: this.apr_goal,
        may_goal: this.may_goal,
        jun_goal: this.jun_goal,
        jul_goal: this.jul_goal,
        aug_goal: this.aug_goal,
        sep_goal: this.sep_goal,
        oct_goal: this.oct_goal,
        nov_goal: this.nov_goal,
        dec_goal: this.dec_goal,
        pricelist: this.sellerPricelist,
      })
        .then(() => {
          this.$apollo.queries.crms.refetch();
          Swal.fire({
            title: "",
            text: "The Company Data has been successfully updated!",
            icon: "success",
            confirmButtonClass: "btn btn-secondary",
          });
        })
        .catch(() => {
          this.$bvToast.toast("An Error Occured!", {
            title: "Error",
            variant: "danger",
            toaster: "b-toaster-bottom-right",
            solid: true,
            appendToast: true,
          });
        });
    },
  },
};
</script>
